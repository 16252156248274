<template>
    <v-card>
        <v-card-title>
            <v-icon>mdi-checkbox-multiple-marked</v-icon> Productos
            <v-spacer></v-spacer>
            <v-text-field
                v-model="product"
                append-icon="mdi-magnify"
                label= "Buscar producto"
                single-line
                hide-details
                @keyup.enter=searchProduct
                @click:append=searchProduct
            ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            v-model="productSelected"
            :headers="headers"
            :items="products"
            item-key='productId'
            :search="product"
            :loading=loadingTable
            single-select
            show-select
        >
            <template v-slot:item.infoProducto="{item}" >
                
                <v-tooltip bottom class="px-0 md-1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-1"
                            v-bind="attrs"
                            v-on="on"
                            color="success"
                            @click="infoProduct(item)"
                        >mdi-information-outline</v-icon>
                         
                    </template>
                    <span>Ver detalle de producto: {{item.productId}}</span>
                </v-tooltip>                
            </template>

            <template v-slot:item.promos="{item}" >
                
                <v-tooltip bottom class="px-0 md-1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-1" v-if="item.promos>0"
                            v-bind="attrs"
                            v-on="on"
                            color="success"
                            @click="infoPromos(item)"
                        >mdi-sale</v-icon>
                         
                    </template>
                    <span>Ver detalle de promociones</span>
                </v-tooltip>                
            </template>

            <template v-slot:item.quantity="{item}">
                <v-text-field
                    style="width: 40px;"
                    v-model.number="item.quantity"
                    min=1
                    dense
                    type="number"
                    single-line
                    hide-details
                    class="text-center"
                ></v-text-field>
            </template>
             
             <template v-slot:item.localInventory="{item}">
                 {{parseInt(item.localInventory == null ? 0: item.localInventory).toFixed(0)}}
             </template>
            <template v-slot:item.externalinventory="{item}">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                            v-bind="attrs"
                            v-on="on"
                            color="success"
                            @click="storeInventory(item)"
                        >mdi-package-variant</v-icon>
                    </template>
                    <span>Ver inventario en otras tiendas</span>
                </v-tooltip>
            </template>
            <template v-slot:footer>
                <v-col class="text-center ">
                    <v-btn 
                        v-if=enabledAddProduct
                        class='mx-1'
                        color="primary"
                        @click=addProduct
                        small
                    > 
                        <v-icon>mdi-cart-plus</v-icon> Agregar 
                    </v-btn>
                    <v-btn 
                        class='mx-1'
                        color="secondary"
                        @click=close
                        small
                    > 
                        <v-icon>mdi-cancel</v-icon> Cerrar 
                    </v-btn>
                </v-col>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
    name:'CaptureProducts',
    props:{
        products:{
            type: Array,
            deafult:[]
        },
        partyId:{
            type: String,
            default: ''
        },
        loadingTable:{
            type: Boolean,
            default: false
        },
        enabledAddProduct:{
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        product:'',
        productSelected:[],
        lsitProducts:[],
        headers:[
           
            { text: 'Marca', value: 'brandName' },
            { text: 'Info.', value: 'infoProducto' },
            { text: 'Promo.', value: 'promos' },
            { text: 'Producto', value: 'internalName' },
            { text: 'Precio', value: 'precioDef' },
            { text: 'Cantidad', value: 'quantity' },
            { text: 'PVP', value: 'precioPvp' },
            { text: 'Precio acuerdo', value: 'precioAcuerdo' },
            { text: 'Inventario local', value: 'localInventory' },
            { text: 'Inv otros locales', value: 'externalinventory' },
            
        ],
    }),
    computed:{
        ...mapState('pos',['ubication'])
    },
    methods: {
        ...mapMutations('master',['setUrl']),

        ...mapActions('master',['requestApi']),

        addProduct(){
            this.$emit('addProductSelected',this.productSelected)
        },

        close(){
            this.$emit('close',false)
        },

        
        searchProduct(){
            
            this.$emit('updateLoadingTable',true) 
            this.dataTable=[]
            this.setUrl('productos')
            this.requestApi({ 
                method : 'GET',
                data:{
                    producto : this.product,
                    bodegaId : this.ubication,
                    partyId: this.partyId
                }
            }).then(res=>{
                this.$emit('updateProducts',res.data) 
                this.$emit('updateLoadingTable',false) 
            })
        },

        storeInventory(item){
            console.log(item)
            this.$emit('storeInventory',{product:item.productId})
        },
        infoProduct(item){
            this.$emit('infoProducto',{product:item.productId})   
        },
        infoPromos(item){
            this.$emit('infoPromo',{product:item.productId})   
        }
    },

}
</script>

<style>

</style>