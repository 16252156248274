<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar flat>
      <v-icon>mdi-file-document-outline</v-icon>

      <v-toolbar-title> # {{ datosCotizacion.quote_id }} </v-toolbar-title>

      <v-divider class="mx-4" vertical></v-divider>
      <!-- <v-btn @click="enviarCotizacion()" dark color="green" small
        ><v-icon>mdi-send</v-icon> Sincronizar</v-btn
      > -->
      <v-btn
        class="mx-4"
        @click="enviarCotizacion()"
        v-if="cantidadItemsCot > 0 && facturada == false"
        dark
        color="blue"
        small
        ><v-icon>mdi-content-save</v-icon> Guardar</v-btn
      >

      <v-btn
        class="mx-4"
        v-if="cantidadItemsCot > 0"
        small
        dark
        color="blue"
        @click="descargarCotizacion"
        ><v-icon>mdi-download</v-icon> Descargar
      </v-btn>

      <v-btn
        class="mx-4"
        v-if="
          datosCotizacion.status_id == 'QUO_CREATED' && cantidadItemsCot > 0
        "
        @click="aceptarCotizacion()"
        dark
        color="green"
        small
        ><v-icon>mdi-check</v-icon>Aceptada</v-btn
      >

      <v-btn
        class="mx-4"
        v-if="puedoRechazarCotizacion"
        @click="rechazarCotizacion()"
        dark
        color="blue"
        small
        ><v-icon>mdi-close</v-icon>Rechazada</v-btn
      >

      <v-btn
        class="mx-4"
        v-if="datosCotizacion.status_id == 'QUO_CREATED'"
        @click="cancelarCotizacion()"
        dark
        text
        color="blue"
        small
        ><v-icon left>mdi-cancel</v-icon>Cancelar
      </v-btn>

      <v-btn
        class="mx-4"
        @click="copiarCotizacion()"
        dark
        color="blue"
        small
        v-if="
          cantidadItemsCot > 0 &&
            !['QUO_CREATED','QUO_CANCELED'].includes(datosCotizacion.status_id) &&
            facturada == false &&
            reservada == false
        "
        ><v-icon left>mdi-content-copy</v-icon>Copiar
      </v-btn>
      <v-btn
          v-if="ordenPendiente && ['QUO_ORDERED','QUO_APPROVED'].includes(datosCotizacion.status_id)"
          class="mx-4"
          :to="`/genera-orden-servicio-manual/${datosCotizacion.quote_id}`"
          dark
          color="blue"
          small
      >
        <v-icon left>mdi-room-service-outline</v-icon>Crear ordenes de servicio
      </v-btn>
      <v-btn
        class="mx-4"
        :to="`/checkout_cot/${datosCotizacion.quote_id}`"
        dark
        color="blue"
        small
        v-if="!facturada && !reservada && datosCotizacion.status_id == 'QUO_APPROVED'"
      >
        <v-icon left>mdi-cart-check</v-icon>Facturar
      </v-btn>      
    </v-toolbar>
    <v-card>
      <v-form ref="formCotizacion">
        <v-col>
          <v-row>
            <v-col md="5">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    label="Lead"
                    readonly
                    v-model="datosOportunidad.cliente_nombre +' :: ' + datosOportunidad.party_id"
                  >
                    <template slot="prepend">
                      <v-btn
                        small
                        icon
                        target="_blank"
                        title="ver datos del lead"
                        :to="`/contacto_form/${datosOportunidad.party_id}`"
                      >
                        <v-icon small>mdi-account-details</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    readonly
                    label="Oportunidad"
                    v-model="datosOportunidad.sales_opportunity_id"
                  >
                    <template slot="append">
                      <v-btn
                        small
                        icon
                        target="_blank"
                        title="ver oportunidad"
                        :to="
                          `/oportunidad_form/${datosOportunidad.sales_opportunity_id}`
                        "
                      >
                        <v-icon small>mdi-eye</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="my-0 py-0">
                  <v-select
                    label="Forma de pago"
                    v-model="datosCotizacion.payment_method_type_id"
                    :items="paymentMethods"
                    item-text="description"
                    :readonly="!puedoEditarFp"
                    item-value="payment_method_type_id"
                    @change="guardarCotizacion()"
                  >
                  </v-select>
                  <!-- <v-text-field
                  label="Forma de pago"
                  v-model="datosCotizacion.payment_method_type_id"
                ></v-text-field> -->
                </v-col>
                <v-col class="my-0 py-0">
                  <v-select
                    :rules="requiredRule"
                    label="Plazo"
                    :readonly="!puedoEditarFp"
                    v-model="datosCotizacion.plazo_id"
                    :items="plazosPm"
                    item-text="descripcion"
                    item-value="plazo_id"
                    @change="guardarPlazoCotizacion()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="my-0 py-0">
                  <v-text-field
                    label="Fecha"
                    readonly
                    v-model="datosCotizacion.issue_date"
                  ></v-text-field>
                </v-col>
                <v-col class="my-0 py-0">
                  <v-text-field label="Estado" readonly v-model="estado_cot">
                    <template slot="append">
                      <v-btn
                        v-if="
                          estado_cot == 'Facturada' ||
                            estado_cot == 'Reservada' ||
                            datosCotizacion.quote_type_id == 'INDIVIDUAL'
                        "
                        small
                        icon
                        target="_blank"
                        title="ver detalles de la cotización"
                        :to="`/cotizacion/${datosCotizacion.quote_id}`"
                      >
                        <v-icon small>mdi-eye</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4">
              <tbody>
                <tr>
                  <td class="td-head-custom">Total PVP</td>
                  <td class="td-custom ">{{ totales.totalSinDescuento }}</td>
                </tr>

                <tr>
                  <td class="td-head-custom">Valor Final</td>
                  <td class="td-custom custom-price">
                    {{ totales.totalConDescuento }}
                  </td>
                </tr>

                <tr>
                  <td class="td-head-custom">Total Descuento</td>
                  <td class="td-custom">
                    <span style="margin-right:50px; "
                      >{{ totales.pctDescuento }}%</span
                    >

                    {{ totales.totalDescuento }}
                  </td>
                </tr>
              </tbody>
              <div style="display:block; margin-top:60px"></div>

              <tbody>
                <tr>
                  <td class="td-head-custom">Total</td>
                  <td class="td-custom custom-price">
                    {{ totales.totalConDescuento }}
                  </td>
                </tr>

                <tr>
                  <td class="td-head-custom">Subtotal con IVA</td>
                  <td class="td-custom ">{{ totales.subTotalIvaNoCero }}</td>
                </tr>

                <tr>
                  <td class="td-head-custom">Subotal sin IVA</td>
                  <td class="td-custom">{{ totales.subTotalIva0 }}</td>
                </tr>

                <!-- <tr>
                <td class="td-head-custom">Total Descuento</td>
                <td class="td-custom">
                  <span style="padding-right:60px; margin:0px; padding:0px"
                    >{{ totales.pctDescuento }}%</span
                  >

                  {{ totales.totalDescuento }}
                </td>
              </tr> -->

                <tr>
                  <td class="td-head-custom">Total de IVA</td>
                  <td class="td-custom">{{ totales.totalIva }}</td>
                </tr>
              </tbody>
            </v-col>
            <v-col md="3">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <td 
                      colspan="3" 
                      class="text-center td-head-custom"
                    >
                      PAGOS
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>#</b></td>
                    <td><b>MONTO</b></td>
                    <td><b>FECHA</b></td>
                  </tr>
                  <tr
                    v-for="(tp, x) in tablaPlazos"
                    :key="x"
                  >
                    <td>{{ x+1 }}</td>
                    <td>{{ parseFloat(tp.monto).toFixed(2) }}</td>
                    <td>{{ tp.fecha }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="6" class="pa-6">
              <v-text-field
                label="buscar servicio / producto"
                dense
                :disabled="!puedoBorrarItem"
                v-model="product"
                append-icon="mdi-magnify"
                @click:append="abrirDialogBuscarProductos"
                @keyup.enter="abrirDialogBuscarProductos"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-4">
              <v-data-table
                :itemsPerPage="itemsPerPage"
                :headers="headersItems"
                :items="itemsCotizacion"
                hide-default-footer
                @click:row="editarItem"
              >
                <template v-slot:item.opciones="{ item }">
                  <v-btn
                    @click.stop="borrarItem(item)"
                    small
                    v-if="puedoBorrarItem"
                    text
                    title="borrar item"
                    ><v-icon small color="red">mdi-delete</v-icon></v-btn
                  >
                </template>
                <template v-slot:item.quote_unit_price="{ item }">
                  {{ parseFloat(item.quote_unit_price).toFixed(4) }}
                </template>

                <template v-slot:item.valor_descuento="{ item }">
                  {{
                    item.valor_descuento != null
                      ? parseFloat(item.valor_descuento).toFixed(4)
                      : 0.0
                  }}
                </template>

                <template v-slot:item.quantity="{ item }">
                  {{ parseInt(item.quantity) }}
                </template>
                <template v-slot:item.product_name="{ item }">
                  {{ item.product_name }}
                </template>
                <template v-slot:item.quote_item_seq_id="{ item }">
                  {{ item.quote_item_seq_id }}
                </template>

                <template v-slot:item.valor_total="{ item }">
                  {{ parseFloat(item.valor_total).toFixed(2) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-form>
    </v-card>
    <v-dialog width="1200" persistent v-model="dialogBuscarProductos">
      <v-card>
        <CaptureProducts
          v-if="dialogBuscarProductos == true"
          :products="listaProductos"
          :loadingTable="loadingTableProducts"
          :partyId="datosCotizacion.product_store_id"
          :enabledAddProduct="!enabledPayments"
          @close="closeDialogInformation"
          @updateProducts="updateProducts"
          @updateLoadingTable="updateLoadingTable"
          @addProductSelected="addProductSelected"
          @storeInventory="storeInventory"
          @infoProducto="infoProducto"
          @infoPromo="infoPromo"
        />
      </v-card>
    </v-dialog>
    <v-dialog width="400" v-model="dialogEditarItem">
      <v-form ref="formEditarItem">
        <v-card>
          <v-row class="px-4 pt-4 pb-0">
            <v-col class="px-2 py-2" cols="6">
              <v-text-field
                label="Cantidad"
                v-model="itemSel.quantity"
              ></v-text-field>
            </v-col>
            <v-col class="px-2 py-2" cols="6">
              <v-text-field
                readonly
                label="Precio unitario"
                v-model="itemSel.quote_unit_price"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-4 py-0">
            <v-col cols="6" class="px-2 py-1">
              <v-text-field
                @keyup="calcularPctDesc"
                label="Valor Descuento"
                :rules="validarValorDesc"
                v-model="itemSel.valor_descuento"
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="px-2 py-1">
              <v-text-field
                @keyup="calcularValorDesc"
                :rules="validarPctDesc"
                label="% Descuento x"
                v-model="itemSel.pct_descuento"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-4 py-0">
            <v-col cols="12" class="px-2 py-1">
              <v-text-field
                label="Valor Total"
                v-model="valorTotalItem"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-toolbar flat class="mt-4 ">
            <v-btn @click="guardarItem" small color="green" dark>Aceptar</v-btn>

            <v-divider vertical class="px-4"></v-divider>
            <v-btn @click="dialogEditarItem = false" small text>Cancelar</v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>


<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
import CaptureProducts from "../general/CaptureProducts";
import moment from 'moment'

export default {
  name: "Cotizacion",
  components: {
    CaptureProducts,
  },
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],

    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 150,
    pageCount: 1,
    listaProductos: [],
    enabledPayments: false,
    loadingTableProducts: false,
    ordenPendiente:false,
    required: [(v) => !!v || "El campo es requerido"],

    headers: [
      { text: "ID", align: "start", value: "sales_opportunity_id" },
      { text: "Nombre", align: "start", value: "opporunity_name" },
      { text: "Cliente", align: "start", value: "cliente_nombre" },
      { text: "Estado", align: "start", value: "sopp_estado" },
      { text: "Monto", align: "start", value: "estimated_amount" },
      {
        text: "Cierre estimado",
        align: "start",
        value: "estimated_close_date",
      },
      { text: "Asignado a", align: "start", value: "created" },
    ],
    oportunidades: [],
    dialogoOportunidad: false,
    dialogBuscarProductos: false,
    datosOportunidad: {
      sales_opportunity_id: "",
      party_id: "",
      monto: 0,
      fecha_estimada_cierre: "",
      estado_id: "",
      estado: "",
      opportunity_name: "",
      cliente_nombres: "",
      description: "",
      next_step: "",
    },

    //establishmentSelected: "10010",
    datosCotizacion: {
      quote_id: "",
      party_id: "",
      lead_nombres: "",
      fecha: "",
      quote_id: "",
      estado_cot: "",
      status_id: "",
      opp_id: "",
    },
    productosOp: [],
    headersItems: [
      { text: "Accion", align: "start", value: "opciones" },
      { text: "Sec.", align: "start", value: "quote_item_seq_id" },
      { text: "Producto", align: "start", value: "product_name" },
      { text: "Cantidad", align: "end", value: "quantity" },
      { text: "Valor unitario", align: "end", value: "quote_unit_price" },
      { text: "Valor Descuento", align: "end", value: "valor_descuento" },
      { text: "%IVA", align: "end", value: "pct_impuesto" },
      { text: "Total", align: "end", value: "valor_total" },
    ],
    itemsCotizacion: [],
    totales: {},
    product: "",
    dialogEditarItem: false,
    itemSel: {
      quote_item_seq_id: "",
      quote_unit_price: 0.0,
      quantity: 0,
      valor_descuento: 0.0,
      pct_descuento: 0.0,
      total: 0.0,
    },
    paymentMethods: [
      { payment_method_type_id: "CASH", description: "EFECTIVO" },
      {
        payment_method_type_id: "EFT_ACCOUNT",
        description: "TRANSFERENCIA ELECTRONICA",
      },
      {
        payment_method_type_id: "CREDIT_CARD",
        description: "TARJ. DE CRÉDITO CORRIENTE",
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF",
        description: "TARJ. DE CRÉDITO DIFERIDO SIN INT.",
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF_INT",
        description: "TARJ. DE CREDITO DIFERIDO CON INT.",
      },
      {
        payment_method_type_id: "DEBIT_CARD",
        description: "TARJ.DE DEBITO",
      },
      {
        payment_method_type_id: "PLACE_TO_PAY_CC",
        description: "PLACE TO PAY TARJ. DE CREDITO ",
      },
      {
        payment_method_type_id: "PLACE_TO_PAY_DC",
        description: "PLACE TO PAY TARJ. DE DEBITO ",
      },
      {
        payment_method_type_id: "CREDITO",
        description: "CREDITO",
      },
    ],
    plazos: [],
    estado_cot: "",
    facturada: false,
    reservada: false,
    order_id: "",
    invoice_number: "",
    invoice_id: "",
    overlay: false,
  }),
  computed: {

    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),

    //habilitar campos y botones
    puedoEditarFp() {
      let res = false;
      if (["QUO_CREATED","QUO_APPROVED"].includes(this.datosCotizacion.status_id)) {
        res = true;
      }
      return res;
    },

    puedoBorrarItem() {
      let res = false;
      if (this.datosCotizacion.status_id == "QUO_CREATED") {
        res = true;
      }
      return res;
    },

    puedoRechazarCotizacion() {
      return (
        (this.datosCotizacion.status_id == "QUO_CREATED" ||
          this.datosCotizacion.status_id == "QUO_APPROVED") &&
        this.itemsCotizacion.length > 0
      );
    },
    ///
    validarValorDesc() {
      return [
        parseFloat(this.itemSel.valor_descuento) <=
          parseFloat(this.itemSel.quote_unit_price) ||
          ` Debe ser menor que ${this.itemSel.quote_unit_price} `,
        parseFloat(this.itemSel.valor_descuento) >= 0 ||
          " Debe ser mayor a cero ",
      ];
    },

    validarPctDesc() {
      return [
        parseFloat(this.itemSel.pct_descuento) <= 100 ||
          `Debe ser menor que 100 `,
        parseFloat(this.itemSel.pct_descuento) >= 0 ||
          " Debe ser mayor a cero ",
      ];
    },

    cantidadItemsCot() {
      return this.itemsCotizacion.length;
    },

    valorTotalItem() {
      //console.log(this.itemSel);
      let valor_desc =
        this.itemSel.valor_descuento == null
          ? 0
          : parseFloat(this.itemSel.valor_descuento);
      let precio_unitario = parseFloat(this.itemSel.quote_unit_price);
      let pct_desc = parseFloat((valor_desc * 100) / precio_unitario);
      let cantidad = this.itemSel.quantity;
      let total = (precio_unitario - valor_desc) * cantidad;

      return total.toFixed(2);
    },

    /*valorDescuentoItem() {

        let precio_unitario = parseFloat(this.itemSel.quote_unit_price);
        let pct_desc = parseFloat(this.itemSel.pct_descuento);
        let valor_desc = (precio_unitario * pct_desc / 100).toFixed(2)

        //let total = (precio_unitario - valor_desc) * cantidad;
        return valor_desc
    },
    pctDescuentoItem() {
        let valor_desc =  this.itemSel.valor_descuento == null ? 0 : parseFloat(this.itemSel.valor_descuento);
        let precio_unitario = parseFloat(this.itemSel.quote_unit_price);
        let pct_desc = parseFloat(valor_desc * 100 / precio_unitario).toFixed(2);
        let cantidad = this.itemSel.quantity;
        //let total = (precio_unitario - valor_desc) * cantidad;

        return pct_desc
    }*/

    plazosPm() {

      return this.plazos.filter(e => e.payment_method_type_id == this.datosCotizacion.payment_method_type_id)

    },

    tablaPlazos() {
      
      let plazo = this.plazosPm.find(e => e.plazo_id == this.datosCotizacion.plazo_id)
      let datos = []

      if(typeof plazo != 'undefined'){

        let montoTotal = parseFloat(this.totales.totalConDescuento)
   
        let hoy = moment().format('YYYY-MM-DD')
        
        console.log(this.plazosPm.find(e => e.plazo_id == this.datosCotizacion.plazo_id))

        let entrada = parseFloat(plazo.entrada)

        if(entrada > 0){

          let monto = parseFloat((montoTotal * (entrada/100)).toFixed(2))

          datos.push({
            monto,
            fecha: hoy,
            dias_plazo: 0,
            porcentaje: entrada
          })
  
          montoTotal-= monto

        }
     
        if(montoTotal > 0){

          let cuota = montoTotal / parseInt(plazo.num_cuotas)
          let dias = 0

          for (let index = 0; index < parseInt(plazo.num_cuotas); index++) {
      
            dias+= parseInt(plazo.dias_cuota)

            let fecha = moment(hoy).add(dias, 'days').format('YYYY-MM-DD')
       
            datos.push({
              monto: cuota,
              fecha,
              dias_plazo: plazo.dias_cuota,
              porcentaje: parseFloat((cuota*100/this.totales.totalConDescuento).toFixed(2))
            })
            
          }

        }

      }
      console.log('datos',datos)
      return datos 

    },

  },
  methods: {

    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
      "setDrawer",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    ...mapActions("access", []),

    cargarOportunidadCotizacion() {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("oportunidad/" + this.$route.query.sop_id);
      this.requestApi({
        method: "GET",
        data: {
          quoteId: this.$route.query.quote_id
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.datosCotizacion = res.data.detail.cotizacion;
          this.datosOportunidad = res.data.detail.oportunidad;
          this.itemsCotizacion = res.data.detail.cotizacion.items;
          this.totales = res.data.detail.cotizacion.totales;
          this.plazos = res.data.detail.plazos;
          this.estado_cot = res.data.detail.estado_cot;
          this.facturada = res.data.detail.facturado;
          this.reservada = res.data.detail.reservado;
          this.order_id = res.data.detail.order_id;
          this.invoice_number = res.data.detail.invoice_number;
          this.invoice_id = res.data.detail.invoice_id;
          this.ordenPendiente = res.data.detail.orden_pendiente;

          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    }
    ,
    guardarCotizacion() {
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "modificarFormaPagoCotizacion",
          datos: {
            formaPagoId: this.datosCotizacion.payment_method_type_id,
            quoteId: this.datosCotizacion.quote_id,
            plazoId: this.datosCotizacion.plazo_id,
          },
        },
      })
        .then((res) => {
          this.alertNotification({
            param: { html: res.data.detail.msg },
          });

          this.cargarOportunidadCotizacion();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarPlazoCotizacion() {

      this.setLoadingTable(true)
      this.setUrl("oportunidad")

      this.requestApi({
        method: "POST",
        data: {
          accion: "modificarPlazoCotizacion",
          datos: {
            quoteId: this.datosCotizacion.quote_id,
            plazoId: this.datosCotizacion.plazo_id,
          },
        },
      })
      .then((res) => {

        this.alertNotification({param: { html: res.data.detail.msg }})

        

        this.cargarOportunidadCotizacion()

      })
      .then(() => {
        this.setLoadingTable(false);
      });
    },

    enviarCotizacion() {
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "sincronizar",
          quoteId: this.datosCotizacion.quote_id,
        },
      })
        .then((res) => {
          //console.log(res.data);

          this.alertNotification({
            param: { html: res.data.detail.msg },
          });
          //this.datosCotizacion = res.data.detail.cotizacion;
          //this.datosOportunidad = res.data.detail.oportunidad;
          //this.itemsCotizacion = res.data.detail.cotizacion.items;
          //this.totales = res.data.detail.cotizacion.totales;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    aceptarCotizacion() {
      if (!this.$refs.formCotizacion.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "aceptarCotizacion",
          quoteId: this.datosCotizacion.quote_id,
          plazos: this.tablaPlazos
        },
      })
        .then((res) => {
          //console.log(res.data);

          this.alertNotification({
            param: { html: res.data.detail.msg },
          });
          //this.datosCotizacion = res.data.detail.cotizacion;
          //this.datosOportunidad = res.data.detail.oportunidad;
          //this.itemsCotizacion = res.data.detail.cotizacion.items;
          //this.totales = res.data.detail.cotizacion.totales;
          this.cargarOportunidadCotizacion();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    rechazarCotizacion() {
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "rechazarCotizacion",
          quoteId: this.datosCotizacion.quote_id,
        },
      })
        .then((res) => {
          //console.log(res.data);

          this.alertNotification({
            param: { html: res.data.detail.msg },
          });
          this.cargarOportunidadCotizacion();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    borrarItem(item) {
      alert("borrar item");
    },

    editarItem(item) {
      //console.log(item)
      if(this.datosCotizacion.status_id != 'QUO_CREATED') return false
      //if (!this.puedoEditarFp) return false;
      let valor_desc =
        item.valor_descuento == null ? 0 : parseFloat(item.valor_descuento);
      let precio_unitario = parseFloat(item.quote_unit_price);
      let pct_desc = parseFloat((valor_desc * 100) / precio_unitario);
      let cantidad = parseInt(item.quantity);
      let total = (precio_unitario - valor_desc) * cantidad;

      this.itemSel.quote_unit_price = precio_unitario.toFixed(6);
      this.itemSel.quantity = cantidad.toFixed(0);
      this.itemSel.valor_descuento = valor_desc.toFixed(6);
      this.itemSel.pct_descuento = pct_desc.toFixed(6);
      this.itemSel.total = total.toFixed(2);
      this.itemSel.quote_item_seq_id = item.quote_item_seq_id;
      this.dialogEditarItem = true;
    },

    handlePageChange(value) {},

    updateLoadingTable(val) {
      this.loadingTableProducts = val;
    },

    addProductSelected(product) {
      const {
        productId,
        quantity,
        codImpuesto,
        costoProm,
        internalName,
        fechaCosto,
        pctImpuesto,
        precioAcuerdo,
        precioDef,
        precioPvp,
        brandName,
        acuerdoId,
        codigo_barra,
      } = product[0];

      this.setUrl("crm-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          accion: "crearItem",
          quoteId: this.datosCotizacion.quote_id,
          productStoreId: this.datosCotizacion.product_store_id,
          productId,
          quantity,
          codImpuesto,
          costoProm,
          internalName,
          fechaCosto,
          pctImpuesto,
          precioAcuerdo,
          precioDef,
          precioPvp,
          brandName,
          acuerdoId,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.msg,
          },
        });

        /*let data = {
          description: res.data.itemDescription,
          seqId: res.data.quoteItemSeqId,
          quantity: res.data.quantity,
          sequence: res.data.secuencia,
          product: productId,
          brand: res.data.marca,
          controlled: res.data.prodControlado,
          psicotropic: res.data.psicotropico,
          pctImp: res.data.pctImpuesto,
          total: res.data.quantity * res.data.quoteUnitPrice,
          stock: res.data.disponible,
          pQuoted: res.data.quoteUnitPrice,
          color: "#fff",
          precioPvp: res.data.precioPvp,
          costoTotal: res.data.costo,
          itemSeqId: res.data.quoteItemSeqId,
          precioDef: parseFloat(res.data.precioEfe),
          precioTcc: res.data.precioTcc,
          precioTcd: res.data.precioTcd,
          precioTd: res.data.precioTd,
          discount: 0,
          isPromo: res.data.is_promo,
          acuerdoId,
          codigo_barra,
          verificado: false,
        };

        this.productsQuotation.push(data);
        this.dataQuotation.items.push(data);*/

        //this.openQutation();
        this.cargarOportunidadCotizacion();
      });
    },

    storeInventory(item) {
      /*this.setUrl(`cotizacion-item/${item.product}`);
    this.requestApi({
      method: "GET",
      data: {
        producStoreId: this.establishmentSelected,
      },
    }).then((res) => {
      this.inventoryItemSelected = item;
      this.storeInventories = res.data.stores;
      this.dialogStoreInventory = true;
    });*/
    },

    infoProducto(item) {
      /*this.setUrl(`info-producto/${item.product}`);
      this.requestApi({
        method: "GET",
        data: {
          producStoreId: this.establishmentSelected,
        },
      }).then((res) => {
        console.log(res.data.detail);
        this.infoProd = res.data.detail.info;
        this.datosPromo = {};
        this.dialogInfoProd = true;
        this.mostrarPromo = false;
      });*/
    },

    infoPromo(item) {
      /*this.setUrl(`info-producto/${item.product}`);
      this.requestApi({
        method: "GET",
        data: {
          producStoreId: this.establishmentSelected,
        },
      }).then((res) => {
        console.log(res.data.detail);
        this.infoProd = res.data.detail.info;
        this.datosPromo = res.data.detail.promos;
        this.dialogInfoProd = true;
        this.mostrarPromo = true;
      });*/
    },

    searchProduct() {
      this.loadingTableProducts = true;
      this.listaProductos = [];
      this.setUrl("productos");
      this.requestApi({
        method: "GET",
        data: {
          producto: this.product,
          bodegaId: this.datosCotizacion.product_store_id,
          productStoreId: this.datosCotizacion.product_store_id,
          partyId: this.datosCotizacion.party_id,
        },
      }).then((res) => {
        // console.log(res.data)
        this.listaProductos = res.data._embedded.productos;
        this.loadingTableProducts = false;
      });

      //this.defaultDialogInformation.maxWidthDialog = "1200px";
      //this.dialogosInformation.addProducts = true;
    },

    closeDialogInformation(val) {
      this.dialogBuscarProductos = val;
    },

    updateProducts(products) {
      this.listaProductos = products._embedded.productos.map((obj) => {
        return Object.assign(obj, { quantity: 1 });
      });
    },

    abrirDialogBuscarProductos() {
      this.searchProduct();
      this.dialogBuscarProductos = true;
    },

    borrarItem(product) {
      this.setUrl("cotizacion-item");
      this.requestApi({
        method: "DELETE",
        data: {
          quoteId: this.datosCotizacion.quote_id,
          seqId: product.quote_item_seq_id,
        },
      })
        .then((res) => {
          this.cargarOportunidadCotizacion();

          this.alertNotification({
            param: {
              html: res.data.detail,
            },
          });
        })
        .then(() => {});
    },

    calcularPctDesc() {
      let valor_desc =
        this.itemSel.valor_descuento == null
          ? 0
          : parseFloat(this.itemSel.valor_descuento);
      let precio_unitario = parseFloat(this.itemSel.quote_unit_price);
      let pct_desc = parseFloat((valor_desc * 100) / precio_unitario);

      this.itemSel.pct_descuento = pct_desc.toFixed(4);
    },

    calcularValorDesc() {
      let precio_unitario = parseFloat(this.itemSel.quote_unit_price);
      let pct_desc = parseFloat(this.itemSel.pct_descuento);
      let valor_desc = ((precio_unitario * pct_desc) / 100).toFixed(4);

      this.itemSel.valor_descuento = valor_desc;
    },

    cancelarCotizacion() {
      this.loadingTableProducts = true;
      this.listaProductos = [];
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "cancelarCotizacion",
          quoteId: this.datosCotizacion.quote_id,
        },
      }).then((res) => {
        // console.log(res.data)
        this.cargarOportunidadCotizacion();
        this.loadingTableProducts = false;
      });
    },

    guardarItem() {
      if (!this.$refs.formEditarItem.validate()) {
        return false;
      }

      //{"quoteId":"COT18476","quoteItemSeqId":"00001","newQuantity":5,"quoteUnitPrice":"44","precioModificado":true,

      this.setUrl(
        "crm-cotizacion"  // + this.datosCotizacion.quote_id + "/actualiza-item"
      );
      this.requestApi({
        method: "POST",
        data: {
          accion:"actualizarItem",
          quoteId: this.datosCotizacion.quote_id,
          quoteItemSeqId: this.itemSel.quote_item_seq_id,
          newQuantity: this.itemSel.quantity,
          quoteUnitPrice: this.itemSel.quote_unit_price,
          precioModificado: false,
          valorDescuento: this.itemSel.valor_descuento,
        },
      })
        .then((res) => {
          this.cargarOportunidadCotizacion();

          this.alertNotification({
            param: {
              html: res.data.detail,
            },
          });
          this.dialogEditarItem = false;
        })
        .then(() => {});
    },

    descargarCotizacion() {
      this.overlay = true;
      this.setUrl("estado-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          typeStore: "downloadQuote",
          quoteId: this.datosCotizacion.quote_id,
        },
      })
        .then((res) => {
          let a = document.createElement("a");
          a.href =
            "data:" +
            res.data.res.msg.ContentType +
            ";base64," +
            res.data.res.msg.datos;
          a.download = res.data.res.msg.nombreArchivo;
          a.click();
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
        });
    },

    copiarCotizacion() {
      Vue.swal({
        html: "Esta seguro de copiar la cotización?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.overlay = true;
          this.setUrl("oportunidad");
          this.requestApi({
            method: "POST",
            data: {
              accion: "copiarCotizacion",
              quoteId: this.datosCotizacion.quote_id,
            },
          }).then((res) => {
            console.log(res);
            this.cargarOportunidadCotizacion();

            if (res.data.detail.msg != "") {
              this.alertNotification({
                param: {
                  html: res.data.detail.msg,
                },
              });

              if (res.data.detail.quoteId != null && res.data.detail.quoteId != "") {
                window.location.href = "/crm_cotizacion?accion=crear_op&sop_id=" +this.datosOportunidad.sales_opportunity_id+"&quote_id="+res.data.detail.quoteId;
              }
            }
          });
        }
      });
    },
    
  },

  mounted() {
    // console.log(this.$route.query)
    this.cargarOportunidadCotizacion();
    this.setTitleToolbar("COTIZACION / OPORTUNIDAD");
    //this.setDrawer(false);
  },
};
</script>

<style>
td.custom-price {
  font-size: 1rem !important;
  font-weight: 600;
}
td {
  height: 25px !important;
  padding: 0 16px;
}
td.td-custom {
  background: #e2e2e2;
  width: 176px;
  text-align: right;
  border-bottom: thin solid rgba(255, 255, 255, 0.82);
  margin: 2px;
}
td.td-head-custom {
  background: #8f8f8f;
  color: #fff;
  font-size: 1rem !important;
  border-bottom: thin solid rgba(255, 255, 255, 0.32);
}
</style>